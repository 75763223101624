import React from "react";
import styled from "@emotion/styled";

function Castyr() {
  return (
    <React.Fragment>
      <Title>Castyr</Title>
      <div>
        <h2>Coming soon!</h2>
      </div>
    </React.Fragment>
  );
}

const Title = styled.div`
  font-size: 24pt;
  float: left;
  text-align: left;
  font-family: "Fira Code", monospace;
`;

export default Castyr;
