import React from "react";
import { BrowserRouter as Switch, Route } from "react-router-dom";
import Castyr from "./projects/Castyr";
import Iterative from "./projects/Iterative/Iterative";
import Redesign from "./projects/redesign/Redesign";
import Shell from "./projects/shell/Shell";
import ArrgPhotos from "./projects/ArrgPhotos";
import Data from "./projects/data/Data";
import styled from "@emotion/styled";

function ProjectFrame(props) {
  return props.location.pathname === "/projects" ? (
    <Switch>
      <Route
        exact
        path={process.env.PUBLIC_URL + "/projects/castyr"}
        component={Castyr}
      />
      <Route
        exact
        path={process.env.PUBLIC_URL + "/projects/iterative"}
        component={Iterative}
      />
      <Route
        exact
        path={process.env.PUBLIC_URL + "/projects/redesign"}
        component={Redesign}
      />
      <Route
        exact
        path={process.env.PUBLIC_URL + "/projects/shell"}
        component={Shell}
      />
      <Route
        exact
        path={process.env.PUBLIC_URL + "/projects/arrg"}
        component={ArrgPhotos}
      />
      <Route
        exact
        path={process.env.PUBLIC_URL + "/projects/data"}
        component={Data}
      />
    </Switch>
  ) : (
    <PageContainer>
      <Switch>
        <Route
          exact
          path={process.env.PUBLIC_URL + "/projects/castyr"}
          component={Castyr}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/projects/iterative"}
          component={Iterative}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/projects/redesign"}
          component={Redesign}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/projects/shell"}
          component={Shell}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/projects/arrg"}
          component={ArrgPhotos}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + "/projects/data"}
          component={Data}
        />
      </Switch>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  margin: 20px 75px 60px;
  max-width: 100%;
  text-align: left;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  @media only screen and (max-width: 1024px) {
    margin: 20px;
    margin-top: 90px;
  }
`;

export default ProjectFrame;
