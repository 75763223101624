import React from "react";

// components
import Navbar from "./Navbar";
import ProjectsView from "./ProjectsView";
import HomePage from "./HomePage";
import ProjectFrame from "./ProjectFrame";
import Passions from "./Passions";

// styling
import styled from "@emotion/styled";

// routing
import { BrowserRouter as Switch, Route } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

function PageView({ location }) {
  return (
    <TransitionGroup>
      <CSSTransition key={location.key} timeout={{ enter: 300, exit: 300 }}>
        <Container>
          <Navbar />
          <ContentContainer>
            <Switch>
              <Route exact path={"/"} component={HomePage} />
              <Route exact path={"/projects"} component={ProjectsView} />
              <Route path={"/projects"} component={ProjectFrame} />
              <Route exact path={"/passions"} component={Passions} />
            </Switch>
          </ContentContainer>
        </Container>
      </CSSTransition>
    </TransitionGroup>
  );
}

const ContentContainer = styled.div`
  margin-top: 50px;
  justify-content: center;
  margin-left: 350px;
  @media only screen and (max-width: 1024px) {
    margin-top: 75px;
    margin-left: 0;
    align-self: center;
  }
`;

const Container = styled.div`
  display: flex;
  @media only screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  background-color: #ffee93;
`;

export default withRouter(PageView);
