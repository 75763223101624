import React from "react";
import ProjectCard from "./ProjectCard";
import styled from "@emotion/styled";
import boba from "../assets/boba.png";
import shell from "../assets/shell.png";
import headphones from "../assets/headphones.png";
import computer from "../assets/computer.png";
import bird from "../assets/bird.png";
import phone from "../assets/phone.png";

const projects = [
  {
    title: "Iterative Design",
    path: "/projects/iterative",
    sketch: bird,
    lang: "Figma",
    description:
      "Worked in a group of four to conceptualize and develop prototype for an existing startup's product and mission",
  },
  {
    title: "Redesign",
    path: "/projects/redesign",
    sketch: boba,
    lang: "HTML, CSS, AdobeXD",
    description:
      "Redesigned Vivi Bubble Tea's website interface to improve usability and learnability",
  },
  {
    title: "Castyr",
    path: "/projects/castyr",
    sketch: headphones,
    lang: "React, Javascript",
    description:
      "Led a team of four engineers in frontend development of MVP -- reviewing code and planning sprints",
  },
  {
    title: "Shell",
    path: "/projects/shell",
    sketch: shell,
    lang: "C",
    description: "Wrote a shell program in C for computer systems course",
  },
  {
    title: "ARrg Photos",
    path: "/projects/arrg",
    sketch: phone,
    lang: "Python",
    description:
      "Worked in group of four to create an AR mobile app that works as a virtual tour of Brown",
  },
  {
    title: "Data Configuration Script",
    path: "/projects/data",
    sketch: computer,
    lang: "Python, Excel",
    description:
      "Created a data scraping and formatting script for my weekly task during an internship",
  },
];

function ProjectsView() {
  return (
    <Container>
      <CardContainer>
        <Header>some things i've worked on!</Header>
        <ProjectsContainer>
          <StyledUl>
            {projects.map((i) => {
              return (
                <StyledLi key={i.title}>
                  <ProjectCard
                    title={i.title}
                    path={i.path}
                    sketch={i.sketch}
                    lang={i.lang}
                    description={i.description}
                  />
                </StyledLi>
              );
            })}
          </StyledUl>
        </ProjectsContainer>
      </CardContainer>
    </Container>
  );
}

const StyledLi = styled.li`
  @media screen and (max-width: 1024px) {
        display: block;
      margin-bottom: 30px;
    }
  `;

const StyledUl = styled.ul`
    text-align: center;
  `;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -60px;
    @media screen and (max-width: 1024px) {
      margin-top: 10px;
    }
  `;

const ProjectsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

const CardContainer = styled.div`
    flex-direction: column;
    margin-top: 30px;
  `;

const Header = styled.div`
    font-size: 16pt;
    text-align: center;
    margin-top: 50px;
  @media screen and (max-width: 1024px) {
        font - size: 14pt;
      margin-bottom: 10px;
    }
  `;

export default ProjectsView;
