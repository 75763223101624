import React, { useState } from "react";
import styled from "@emotion/styled";
import Github from "../assets/github.png";
import Medium from "../assets/medium.png";
import Linkedin from "../assets/linkedin.png";
import Resume from "../assets/Gabriela_Asuncion052021.pdf";
import Mail from "../assets/mail.png";
import Hamburger from "../assets/hamburger.png";
import Close from "../assets/close.png";

function Navbar() {
  return (
    <Sidebar>
      <Header>
        gabriela
        <br />
        asuncion
        <MediaNav />
      </Header>
      <HamburgerMenu /> <Links />
    </Sidebar>
  );
}

function HamburgerMenu() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MobileMenu>
      <HamburgerButton onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? (
          <img src={Close} width="15px" alt="close hamburger" />
        ) : (
            <img src={Hamburger} width="20px" alt="open hamburger" />
          )}
      </HamburgerButton>
      {isOpen && (
        <MobileMenuLinks>
          <NavItem>
            <MobileLink eventKey="1" href="/">
              home
            </MobileLink>
          </NavItem>
          <NavItem>
            <MobileLink eventKey="2" href="/projects">
              projects
            </MobileLink>
          </NavItem>
          <NavItem>
            <MobileLink eventKey="3" href={Resume}>
              passions
            </MobileLink>
          </NavItem>
          <NavItem>
            <MobileLink eventKey="4" href={Resume}>
              resume
            </MobileLink>
          </NavItem>
        </MobileMenuLinks>
      )}
    </MobileMenu>
  );
}

function Links() {
  return (
    <NavWrapper>
      <NavItem>
        <NavLink eventKey="1" href="/">
          home
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink eventKey="2" href="/projects">
          projects
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink eventKey="3" href="/passions">
          passions
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink eventKey="4" href={Resume}>
          resume
        </NavLink>
      </NavItem>
    </NavWrapper>
  );
}

function MediaNav() {
  return (
    <MediaLinks>
      <NavItem>
        <a
          href="https://github.com/gabbyasuncion"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Github} alt="github" width="20px" />
        </a>
      </NavItem>
      <NavItem>
        <a
          href="https://www.linkedin.com/in/gabriela-asuncion/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Linkedin} alt="linkedin" width="20px" />
        </a>
      </NavItem>
      <NavItem>
        <a
          href="https://medium.com/@gabriela_asuncion"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Medium} alt="medium" width="20px" />
        </a>
      </NavItem>
      <NavItem>
        <a
          href="mailto:gabriela_asuncion@brown.edu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Mail} alt="mail" width="20px" />
        </a>
      </NavItem>
    </MediaLinks>
  );
}

const Sidebar = styled.div`
  margin-left: 60px;
  margin-top: 60px;
  width: 230px;
  display: flex;
  flex-direction: column;
  position: fixed;
  @media only screen and (max-width: 1024px) {
    max-height: 50px;
    margin: 20px;
    flex-direction: row;
    justify-content: space-between;
    width: 92%;
    position: absolute;
  }
`;

const MobileLink = styled.a`
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-size: 14pt;
  color: black;
  cursor: pointer;
  position: relative;
  padding-right: 15px;
`;

const MobileMenu = styled.div`
  display: none;
  @media only screen and (max-width: 420px) {
    display: unset;
    float: right;
    text-align: right;
  }
`;

const HamburgerButton = styled.button`
  background: none;
  border: none;
  outline: none;
  margin: 10px;
`;

const MobileMenuLinks = styled.div``;

const MediaLinks = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: -10px;
  @media only screen and (max-width: 1024px) {
    margin-top: 0px;
  }
`;

const NavWrapper = styled.div`
  @media only screen and (max-width: 1024px) {
    display: flex;
    margin: 0;
    margin-top: 10px;
    float: right;
  }
  margin-top: 10px;
  margin-left: 60px;
  @media only screen and (max-width: 420px) {
    display: none;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Fira Code", monospace;
  font-size: 36pt;
  margin-bottom: -10px;
  @media only screen and (max-width: 1024px) {
    float: left;
    font-size: 24pt;
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-size: 14pt;
  color: black;
  cursor: pointer;
  position: relative;
  padding: 7px 0px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: all 0.01s;
  width: 100px;
  display: flex;
  justify-content: center;
  &:after,
  &:before {
    content: " ";
    width: 10px;
    height: 10px;
    position: absolute;
    border: 0px solid #fff;
    transition: all 0.5s;
  }
  &:after {
    top: 1px;
    left: 1px;
    border-top: 2px solid black;
    border-left: 2px solid black;
  }
  &:before {
    bottom: 1px;
    right: 1px;
    border-bottom: 2px solid black;
    border-right: 2px solid black;
  }
  &:hover {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    color: black;
    &:before,
    &:after {
      width: 100%;
      height: 100%;
    }
  }
  @media only screen and (max-width: 1024px) {
    font-size: 10pt;
    padding: 4px 0px;
    width: 70px;
    margin: 0px 7px;
    &:after {
      top: 1px;
      left: 1px;
      border-top: 1px solid black;
      border-left: 1px solid black;
    }
    &:before {
      bottom: 1px;
      right: 1px;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
    }
  }
  @media only screen and (max-width: 430px) {
    font-size: 10pt;
    padding: 4px 0px;
    width: 80px;
    margin: 0px 7px;
    &:after {
      top: 1px;
      left: 1px;
      border-top: 1px solid black;
      border-left: 1px solid black;
    }
    &:before {
      bottom: 1px;
      right: 1px;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
    }
  }
`;

const NavItem = styled.div`
  padding: 10px 0px;
  @media only screen and (max-width: 1024px) {
    padding: 0px;
  }
`;

export default Navbar;
