import React from "react";
import styled from "@emotion/styled";
import Face from "../assets/short-hair-layered.png";

function HomePage() {
  return (
    <Container>
      <FaceContainer>
        <img src={Face} alt="face" style={{ height: "80%" }} />
      </FaceContainer>
      <TextContainer>
        <Intro>>> hello world! i'm gabby ★</Intro>
        <SubIntro>
          i'm passionate about the intersection of art and tech.
        </SubIntro>
        <Section>
          <About>
            currently a software engineer at{" "}
            <a
              href="https://www.mongodb.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              MongoDB
            </a>
            . recent grad from brown university with a BA in computer science
            and art history.
          </About>
          <About>
            previously a software engineering intern at{" "}
            <a
              href="https://www.mongodb.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              MongoDB
            </a>{" "}
            &{" "}
            <a
              href="https://www.goguardian.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              GoGuardian
            </a>{" "}
            and a strategic partnerships intern at{" "}
            <a
              href="https://puls.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              Puls
            </a>
            .
          </About>
          <About>
            lover of dancing, sustainable fashion, museum gift shops, and oatmilk lattes.
          </About>
        </Section>
      </TextContainer>
    </Container>
  );
}

const TextContainer = styled.div`
  float: right;
  margin: 0px 30px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8vh 2vw 0vh;
  width: 70vw;
  @media only screen and (max-width: 1430px) {
    width: 60vw;
    flex-direction: column;
  }
  @media only screen and (max-width: 1024px) {
    justify-content: center;
    width: 95%;
  }
`;

const Section = styled.div`
  float: right;
  @media only screen and (min-width: 1430px) {
    max-width: 750px;
  }
  @media only screen and (max-width: 1430px) {
    max-width: 1000px;
  }
`;

const Intro = styled.div`
  font-size: 24pt;
  margin-bottom: 10px;
  text-align: right;
`;

const SubIntro = styled.div`
  font-size: 14pt;
  margin-bottom: 30px;
  text-align: right;
`;

const FaceContainer = styled.div`
  float: left;
  display: flex;
  @media only screen and (min-width: 1430px) {
    height: 550px;
    padding-top: 100px;
    margin-right: 50px;
  }
  @media only screen and (max-width: 1430px) {
    height: 400px;
    margin-top: 50px;
    margin-bottom: 0px;
  }
  justify-content: center;
`;

const About = styled.div`
  font-size: 13pt;
  font-family: "Open Sans", sans-serif;
  text-align: justify;
  a {
    color: gray;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
    &:hover {
      color: #79addc;
    }
  }
  padding: 7px 0px;
`;

export default HomePage;
