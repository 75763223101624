import React from "react";
import { BrowserRouter as Switch, Route } from "react-router-dom";
import PageView from "./components/PageView";
import "./index.css";

function App() {
  return (
    <Switch>
      <Route path="/" component={PageView} />
    </Switch>
  );
}

export default App;
