import React from "react";

import styled from "@emotion/styled";

const Header = styled.div`
    font-size: 16pt;
    text-align: center;
    margin-top: 50px;
  @media screen and (max-width: 1024px) {
        font - size: 14pt;
      margin-bottom: 10px;
    }
  `;

export default function Passions() {
  return (
    <Header>coming soon! (i just moved to nyc and still need to buy a desk so passions are slow rn)</Header>
  )
}